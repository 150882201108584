<template>
  <a-spin :spinning="ourData.sidebar_loading">
    <a-menu
      :mode="mode"
      :theme="darkMode ? 'dark' : 'light'"
    >
      <a-menu-item
        key="Index.Page"
        @click="toggleCollapsed"
      >
        <router-link :to="{ name: 'Index.Page' }">
          <sdFeatherIcons type="users" />
          <span>
            Müşteriler
            <span class="badge badge-primary menuItem">{{ ourData.total_customer_count }}</span>
          </span>
        </router-link>
      </a-menu-item>

      <a-sub-menu key="Cargong.Pages">
        <template #title>
          <sdFeatherIcons type="align-left" />
          <span>Cargong</span>
        </template>
        <a-menu-item
          v-if="ourData.permission_codes.includes('manage-accounts')"
          key="CargongAccountList.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'CargongAccountList.Page' }">
            <sdFeatherIcons type="users" />
            <span>
              Hesaplar
              <span class="badge badge-primary menuItem">{{ ourData.total_cargong_account_count }}</span>
            </span>
          </router-link>
        </a-menu-item>
        <a-menu-item
          v-if="ourData.permission_codes.includes('manage-shipments')"
          key="CargongShipmentList.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'CargongShipmentList.Page' }">
            <sdFeatherIcons type="truck" />
            <span>
              Gönderiler
              <span class="badge badge-primary menuItem">{{ ourData.total_cargong_shipment_count }}</span>
            </span>
          </router-link>
        </a-menu-item>
        <a-menu-item
          v-if="ourData.permission_codes.includes('manage-payments')"
          key="CargongPaymentList.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'CargongPaymentList.Page' }">
            <sdFeatherIcons type="dollar-sign" />
            <span> Ödeme Listesi </span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu key="TsoftKargo.Pages">
        <template #title>
          <sdFeatherIcons type="align-left" />
          <span>T-Soft Kargo</span>
        </template>
        <a-menu-item
          v-if="ourData.permission_codes.includes('manage-accounts')"
          key="TsoftKargoAccountList.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'TsoftKargoAccountList.Page' }">
            <sdFeatherIcons type="users" />
            <span>
              Hesaplar
              <span class="badge badge-primary menuItem">{{ ourData.total_tsoft_kargo_account_count }}</span>
            </span>
          </router-link>
        </a-menu-item>
        <a-menu-item
          v-if="ourData.permission_codes.includes('manage-shipments')"
          key="TsoftKargoShipmentList.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'TsoftKargoShipmentList.Page' }">
            <sdFeatherIcons type="truck" />
            <span>
              Gönderiler
              <span class="badge badge-primary menuItem">{{ ourData.total_tsoft_kargo_shipment_count }}</span>
            </span>
          </router-link>
        </a-menu-item>
        <a-menu-item
          v-if="ourData.permission_codes.includes('manage-payments')"
          key="TsoftKargoPaymentList.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'TsoftKargoPaymentList.Page' }">
            <sdFeatherIcons type="dollar-sign" />
            <span> Ödeme Listesi </span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item
        v-if="ourData.permission_codes.includes('manage-shipping-companies')"
        key="ContractedCargo.Page"
        @click="toggleCollapsed"
      >
        <router-link :to="{ name: 'ContractedCargo.Page' }">
          <sdFeatherIcons type="truck" />
          <span> Anlaşmalı Kargo </span>
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="ourData.permission_codes.includes('manage-customer-groups')"
        key="UserGroups.Page"
        @click="toggleCollapsed"
      >
        <router-link :to="{ name: 'UserGroups.Page' }">
          <sdFeatherIcons type="users" />
          <span> Üye Grupları </span>
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="ourData.permission_codes.includes('manage-customers')"
        key="UserGroupsMatch.Page"
        @click="toggleCollapsed"
      >
        <router-link :to="{ name: 'UserGroupsMatch.Page' }">
          <sdFeatherIcons type="repeat" />
          <span> Üye Grup Eşleştirmesi </span>
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="ourData.permission_codes.includes('manage-customer-transactions')"
        key="CustomerMovementsDetail.Page"
        @click="toggleCollapsed"
      >
        <router-link :to="{ name: 'CustomerMovementsDetail.Page' }">
          <sdFeatherIcons type="command" />
          <span> Müşteri Hareketleri </span>
        </router-link>
      </a-menu-item>

      <a-sub-menu
        v-if="ourData.permission_codes.includes('manage-incoming-invoices')"
        key="IncomingInvoices.Pages"
      >
        <template #title>
          <sdFeatherIcons type="align-left" />
          <span>Gelen Faturalar</span>
        </template>
        <a-menu-item
          key="IncomingInvoices.List.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'IncomingInvoices.List.Page' }">
            <sdFeatherIcons type="file" />
            &nbsp;Fatura Listesi
          </router-link>
        </a-menu-item>
        <a-menu-item
          key="IncomingInvoices.Detail.Page"
          @click="toggleCollapsed"
        >
          <router-link :to="{ name: 'IncomingInvoices.Detail.Page' }">
            <sdFeatherIcons type="file-text" />
            &nbsp;Fatura Liste Detayları
          </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item
        v-if="ourData.permission_codes.includes('manage-consensus')"
        key="Consensus.Page"
        @click="toggleCollapsed"
      >
        <router-link :to="{ name: 'Consensus.Page' }">
          <sdFeatherIcons type="minimize-2" />
          <span> Mutabakat </span>
        </router-link>
      </a-menu-item>

      <a-menu-item
        v-if="ourData.permission_codes.includes('manage-admins')"
        key="AdminList.Page"
        @click="toggleCollapsed"
      >
        <router-link :to="{ name: 'AdminList.Page' }">
          <sdFeatherIcons type="users" />
          <span> Yöneticiler </span>
        </router-link>
      </a-menu-item>
    </a-menu>
  </a-spin>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs } from 'vue'
import VueTypes from 'vue-types'
import { useStore } from 'vuex'
import restMethods from '@/utility/restMethods'

export default {
  name: 'AsideItems',
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore()
    const darkMode = computed(() => store.state.themeLayout.data)
    const mode = ref('inline')
    const { events } = toRefs(props)
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value

    const ourData = reactive({
      sidebar_loading: false,

      permission_codes: [],

      total_customer_count: 0,

      total_cargong_account_count: 0,
      total_tsoft_kargo_account_count: 0,

      total_cargong_shipment_count: 0,
      total_tsoft_kargo_shipment_count: 0,
    })

    onMounted(() => {
      ourData.sidebar_loading = true
      restMethods.postForNoc('/get-sidebar').then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()

          ourData.permission_codes = data.permission_codes

          ourData.total_customer_count = data.total_customer_count

          ourData.total_cargong_account_count = data.total_cargong_account_count
          ourData.total_tsoft_kargo_account_count = data.total_tsoft_kargo_account_count

          ourData.total_cargong_shipment_count = data.total_cargong_shipment_count
          ourData.total_tsoft_kargo_shipment_count = data.total_tsoft_kargo_shipment_count
        }
        ourData.sidebar_loading = false
      })
    })

    return {
      mode,
      ourData,
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    }
  },
}
</script>